import { useMutation } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';

const useUpdate = (page: string) => {
  const updateFn = async ({data, id}: {data: any, id: number}) => {
   return await axiosInstance({
      method: 'put',
      url: `/${page}/${id}`,
      data
    });
  };

  const updateMutation = useMutation({
    mutationFn: updateFn
  });

  return {
    updateMutation,
    updateFn
  };
};

export default useUpdate;
