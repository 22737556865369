import { Alert, Paper } from '@mui/material';
import Title from '../components/shared/title/Title';
import Table from '../components/shared/table/Table';
import LoadingPage from '../pages/Loading/Loading';
import ErrorPage from '../pages/Error/Error';
import useFetch from '../hooks/useFetch';
//interfaces
import { Category } from '@src/domain/interfaces/category';
import { Option } from '@src/domain/interfaces/option';
import { Crud } from '@src/domain/interfaces/crud';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PageLayout = ({
  title,
  columns,
  page,
  crud,
  selectedCategories,
  selectedOptions,
  selectedUser,
  selectedProducts,
  specificData,
  productSpecificData,
  productCheckedAvailable,
  productCheckedCurrently,
  subcategories,
  productPreviewImage,
  productAvailable,
  setProductAvailable,
  setProductCheckedAvailable,
  setProductCheckedCurrently,
  setProductShownImages,
  setPreviewImageURL,
  setFeatured,
  setProductDiffOptions,
  setSelectedCategories,
  productInDrafts,
  setProductInDrafts
}: {
  title: string;
  columns: any;
  page: string;
  crud: Crud;
  isAvailable?: boolean;
  selectedCategories?: Category[];
  selectedOptions?: Option[];
  selectedUser?: any;
  selectedProducts?: any;
  specificData?: any;
  productSpecificData?: any;
  productCheckedAvailable?: any;
  productCheckedCurrently?: any;
  subcategories?: any;
  productPreviewImage?: string;
  productAvailable?: boolean;
  setProductAvailable?: React.SetStateAction<any>;
  setProductCheckedAvailable?: React.SetStateAction<any>;
  setProductCheckedCurrently?: React.SetStateAction<any>;
  setProductShownImages?: React.SetStateAction<any>;
  setPreviewImageURL?: React.SetStateAction<any>;
  setFeatured?: React.SetStateAction<any>;
  setProductDiffOptions?: React.SetStateAction<any>;
  setSelectedCategories?: React.SetStateAction<any>;
  productInDrafts?: boolean;
  setProductInDrafts?: React.SetStateAction<any>;
}) => {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [pagination, setPagination] = useState<{ pageIndex: number; pageSize: number }>({
    pageIndex: 0,
    pageSize: 20 //customize the default page size
  });
  const { data, isFetched, isLoading, isError, refetch } = useFetch(page, pagination, globalFilter);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const { t } = useTranslation();

  setTimeout(() => setSuccess(false), 5000);
  setTimeout(() => setError(false), 5000);
  return (
    <Paper sx={{ boxShadow: 'none' }}>
      {success && (
        <Alert severity="success" sx={{ position: 'sticky', top: '10px', zIndex: 1000 }}>
          {t('successfully')} {t(action)} {t(page)}
        </Alert>
      )}
      {error && (
        <Alert severity="error" sx={{ position: 'sticky', top: '10px', zIndex: 1000 }}>
          {t('somethingWrong')}. {t('unsuccessfully')} {t(action)} {t(page)}
        </Alert>
      )}
      {isFetched && <Title title={title} />}
      {isLoading && <LoadingPage />}
      {isError && <ErrorPage />}
      {isFetched && (
        <Table
          columns={columns}
          data={data?.Data}
          dataLength={data?.Length}
          page={page}
          crud={crud}
          refetch={refetch}
          isLoading={isLoading}
          selectedCategories={selectedCategories}
          selectedProducts={selectedProducts}
          setSuccess={setSuccess}
          setError={setError}
          setAction={setAction}
          specificData={productSpecificData}
          checkedAvailable={productCheckedAvailable}
          checkedCurrently={productCheckedCurrently}
          subcategories={subcategories}
          previewImage={productPreviewImage}
          available={productAvailable}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          pagination={pagination}
          setPagination={setPagination}
          setProductAvailable={setProductAvailable}
          setProductCheckedAvailable={setProductCheckedAvailable}
          setProductCheckedCurrently={setProductCheckedCurrently}
          setProductShownImages={setProductShownImages}
          setPreviewImageURL={setPreviewImageURL}
          setFeatured={setFeatured}
          setProductDiffOptions={setProductDiffOptions}
          setSelectedCategories={setSelectedCategories}
          productInDrafts={productInDrafts}
          setProductInDrafts={setProductInDrafts}
        />
      )}
    </Paper>
  );
};

export default PageLayout;
