import React, { useMemo } from 'react';
import { Crud } from '@src/domain/interfaces/crud';
import PageLayout from '@src/ui/layouts/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import { useTranslation } from 'react-i18next';
import { CustomRequestMessage } from '@src/domain/interfaces/contact';

const CustomRequestsPage = () => {
  const { t } = useTranslation();
  const requestsColumns = useMemo<MRT_ColumnDef<CustomRequestMessage>[]>(
    () => [
      { accessorKey: 'ID', header: 'Custom request id', enableEditng: false },
      { accessorKey: 'Name', header: t('name'), enableEditng: false },
      { accessorKey: 'Email', header: 'Email', enableEditng: false },
      { accessorKey: 'Phone', header: t('phone'), enableEditng: false },
      { accessorKey: 'Message', header: t('message'), enableEditng: false },
      {
        accessorKey: 'Status',
        header: 'Status',
        editVariant: 'select',
        editSelectOptions: ['Unread', 'Read', 'Confirmed', 'Rejected']
      }
    ],
    [t]
  );
  const crud: Crud = { canCreate: false, canDelete: true, canEdit: true };

  return (
    <PageLayout
      crud={crud}
      columns={requestsColumns}
      title="customRequests"
      page="custom-request"
    />
  );
};

export default CustomRequestsPage;
