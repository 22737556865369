import { useRecoilState } from 'recoil';
import { categoryErrorAtom } from '../../domain/atoms/errorAtoms/CategoryErrorAtom';
import { collectionErrorAtom } from '../../domain/atoms/errorAtoms/CollectionErrorAtom';
import { userErrorAtom } from '../../domain/atoms/errorAtoms/UserErrorAtom';
import { productErrorAtom } from '../../domain/atoms/errorAtoms/ProductErrorAtom';
import { pressErrorAtom } from '../../domain/atoms/errorAtoms/PressErrorAtom';
import { orderErrorAtom } from '../../domain/atoms/errorAtoms/OrderErrorAtom';
import { countryErrorAtom } from '../../domain/atoms/errorAtoms/CountryErrorAtom';
import { optionErrorAtom } from '../../domain/atoms/errorAtoms/OptionErrorAtom';
import { useEffect } from 'react';
import { transactionErrorAtom } from '../../domain/atoms/errorAtoms/TransactionErrorAtom';
import { useTranslation } from 'react-i18next';
import { number, object, string } from 'yup';

const useValidate = (page: string) => {
  const [categoryErrors, setCategoryErrors] = useRecoilState<any>(categoryErrorAtom);
  const [collectionErrors, setCollectionErrors] = useRecoilState<any>(collectionErrorAtom);
  const [countryErrors, setCountryErrors] = useRecoilState<any>(countryErrorAtom);
  const [optionErrors, setOptionErrors] = useRecoilState<any>(optionErrorAtom);
  const [orderErrors, setOrderErrors] = useRecoilState<any>(orderErrorAtom);
  const [pressErrors, setPressErrors] = useRecoilState<any>(pressErrorAtom);
  const [productErrors, setProductErrors] = useRecoilState<any>(productErrorAtom);
  const [transactionErrors, setTransactionErrors] = useRecoilState<any>(transactionErrorAtom);
  const [userErrors, setUserErrors] = useRecoilState<any>(userErrorAtom);
  const { i18n } = useTranslation();
  let isError: boolean = false;
  useEffect(() => {
    setCategoryErrors({});
    setCollectionErrors({});
    setCountryErrors({});
    setOptionErrors({});
    setOrderErrors({});
    setPressErrors({});
    setProductErrors({});
    setTransactionErrors({});
    setUserErrors({});
  }, [page]);

  const errorMsgs: any = {};

  interface ErrorMessages {
    [locale: string]: {
      nameRequiredMsg: string;
      orderRequiredMsg: string;
      subnameRequiredMsg: string;
      descriptionRequiredMsg: string;
      intlcodeRequiredMsg: string;
      typeRequiredMsg: string;
      dateRequiredMsg: string;
      compositionRequiredMsg: string;
      skuRequiredMsg: string;
      vatRequiredMsg: string;
      discountRequiredMsg: string;
      discountpriceRequiredMsg: string;
      originalpriceRequiredMsg: string;
      countryIDRequiredMsg: string;
      emailRequiredMsg: string;
      firstnameRequiredMsg: string;
      lastnameRequiredMsg: string;
      addressRequiredMsg: string;
      cityRequiredMsg: string;
      postcodeRequiredMsg: string;
      phoneRequiredMsg: string;
      passwordRequiredMsg: string;
    };
  }

  const errorMessages: ErrorMessages = {
    en: {
      nameRequiredMsg: 'Name is required field!',
      orderRequiredMsg: 'Order is required field and must be a positive number!',
      subnameRequiredMsg: 'Subname is required field!',
      descriptionRequiredMsg: 'Description is required field!',
      intlcodeRequiredMsg: 'International Code is required field!',
      typeRequiredMsg: 'Type is required field!',
      dateRequiredMsg: 'Date is required field!',
      compositionRequiredMsg: 'Composition is required field!',
      skuRequiredMsg: 'Sku is required field!',
      vatRequiredMsg: 'Vat is required field!',
      discountRequiredMsg: 'Discount must be a number!',
      discountpriceRequiredMsg: 'Discount price must be a number!',
      originalpriceRequiredMsg: 'Original price must be a number!',
      countryIDRequiredMsg: 'Country is a required field!',
      emailRequiredMsg: 'Email  is a required field!',
      firstnameRequiredMsg: 'First name is a required field!',
      lastnameRequiredMsg: 'Last name is a required field!',
      addressRequiredMsg: 'Address is a required field!',
      cityRequiredMsg: 'City is a required field!',
      postcodeRequiredMsg: 'Postcode is a required field!',
      phoneRequiredMsg: 'Phone is a required field!',
      passwordRequiredMsg: 'Password must be at least 8 characters!'
    },
    sr: {
      nameRequiredMsg: 'Ime je obavezno polje!',
      orderRequiredMsg: 'Vrednost je obavezno polje i mora biti pozitivan broj!',
      subnameRequiredMsg: 'Podime je obavezno polje!',
      descriptionRequiredMsg: 'Opis je obavezno polje!',
      intlcodeRequiredMsg: 'Medjunarodni kod je obavezno polje!',
      typeRequiredMsg: 'Tip je obavezno polje!',
      dateRequiredMsg: 'Datum je obavezno polje!',
      compositionRequiredMsg: 'Kompozicija je obavezno polje!',
      skuRequiredMsg: 'Sifra proizvoda je obavezno polje!',
      vatRequiredMsg: 'PDV je obavezno polje i mora biti broj!',
      discountRequiredMsg: 'Popust mora biti broj!',
      discountpriceRequiredMsg: 'Cena sa popustom mora biti broj!',
      originalpriceRequiredMsg: 'Originalna cena mora biti broj!',
      countryIDRequiredMsg: 'Zemlja je obavezno polje!',
      emailRequiredMsg: 'Email je obavezno polje i mora biti validan!',
      firstnameRequiredMsg: 'Prvo ime je obavezno polje!',
      lastnameRequiredMsg: 'Prezime je obavezno polje!',
      addressRequiredMsg: 'Adresa je obavezno polje!',
      cityRequiredMsg: 'Grad je obavezno polje!',
      postcodeRequiredMsg: 'Postanski broj je obavezno polje!',
      phoneRequiredMsg: 'Broj telefona je obavezno polje!',
      passwordRequiredMsg: 'Sifra mora imati minimum 8 karaktera!'
    }
  };

  const categorySchema = object({
    Name: string().required(errorMessages[i18n.language].nameRequiredMsg),
    Order: number()
      .required(errorMessages[i18n.language].orderRequiredMsg)
      .positive(errorMessages[i18n.language].orderRequiredMsg)
  });

  const collectionSchema = object({
    Name: string().required(errorMessages[i18n.language].nameRequiredMsg),
  });

  const countrySchema = object({
    Name: string().required(errorMessages[i18n.language].nameRequiredMsg),
    InternationalCode: string().required(errorMessages[i18n.language].intlcodeRequiredMsg),
    Order: number()
      .required(errorMessages[i18n.language].orderRequiredMsg)
      .positive(errorMessages[i18n.language].orderRequiredMsg)
  });

  const optionSchema = object({
    Name: string().required(errorMessages[i18n.language].nameRequiredMsg),
    Type: string().required(errorMessages[i18n.language].typeRequiredMsg)
  });

  const orderSchema = object({
    // Metadata: string().required(),
    // Note: string().required(),
    // ShippingAddress: string().required(errorMessages[i18n.language].typeRequiredMsg),
    // ShippingType: string().required(errorMessages[i18n.language].typeRequiredMsg),
    // Subototal: number().required(errorMessages[i18n.language].typeRequiredMsg),
    // Total: number().required(errorMessages[i18n.language].typeRequiredMsg),
    // UserID: number().required(errorMessages[i18n.language].typeRequiredMsg)
  });

  const pressSchema = object({
    Name: string().required(errorMessages[i18n.language].nameRequiredMsg),
    Type: string().required(errorMessages[i18n.language].typeRequiredMsg),
    Date: string().required(errorMessages[i18n.language].dateRequiredMsg),
    Description: string().required(errorMessages[i18n.language].descriptionRequiredMsg)
  });

  const productSchema = object({
    Name: string().required(errorMessages[i18n.language].nameRequiredMsg),
    Sku: string().required(errorMessages[i18n.language].skuRequiredMsg),
    Vat: number().required(errorMessages[i18n.language].vatRequiredMsg),
    Discount: number(),
    DiscountPrice: number().required(errorMessages[i18n.language].discountpriceRequiredMsg),
    OriginalPrice: number().required(errorMessages[i18n.language].originalpriceRequiredMsg)
  });

  const transactionSchema = object({
    OrderID: number().required(),
    PaymentMethod: string().required(),
    PaymentAmount: number().required(),
    PaymentProvider: string().required(),
    status: string().required()
  });

  const userSchema = object({
    Address: string().required(errorMessages[i18n.language].addressRequiredMsg),
    City: string().required(errorMessages[i18n.language].cityRequiredMsg),
    CountryID: number().required(errorMessages[i18n.language].countryIDRequiredMsg),
    Email: string().required(errorMessages[i18n.language].emailRequiredMsg),
    FirstName: string().required(errorMessages[i18n.language].firstnameRequiredMsg),
    LastName: string().required(errorMessages[i18n.language].lastnameRequiredMsg),
    Password: number().min(8).required(errorMessages[i18n.language].passwordRequiredMsg),
    Phone: number().required(errorMessages[i18n.language].phoneRequiredMsg),
    Postcode: string().required(errorMessages[i18n.language].postcodeRequiredMsg)
  });

  const validateData = async (apiData: any) => {
    switch (page) {
      case 'category':
        await categorySchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setCategoryErrors(errorMsgs));
        break;
      case 'collection':
        await collectionSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setCollectionErrors(errorMsgs));
        break;
      case 'country':
        await countrySchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setCountryErrors(errorMsgs));
        break;
      case 'option':
        await optionSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setOptionErrors(errorMsgs));
        break;

      case 'order':
        await orderSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setOrderErrors(errorMsgs));
        break;
      case 'press':
        await pressSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setPressErrors(errorMsgs));
        break;
      case 'product':
        await productSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              console.log(error.path)
              console.log(error.message)
              errorMsgs[error.path] = error.message;
            });

            isError = true;
          })
          .then(() => setProductErrors(errorMsgs));
        break;
      case 'transaction':
        await transactionSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setTransactionErrors(errorMsgs));
        break;
      case 'user':
        await userSchema
          .validate(apiData, { abortEarly: false })
          .catch(ValidationError => {
            ValidationError.inner.forEach((error: any) => {
              errorMsgs[error.path] = error.message;
            });
            isError = true;
          })
          .then(() => setUserErrors(errorMsgs));
        break;
    }

    return isError;
  };

  return { validateData };
};

export default useValidate;
