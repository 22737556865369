import '../../../styles/components/sidebar.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import axiosInstance from '@src/infrastructure/services/config';
//icons
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import LanguageIcon from '@mui/icons-material/Language';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import PhoneIcon from '@mui/icons-material/Phone';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NewspaperIcon from '@mui/icons-material/Newspaper';
//mui components
import { Button, IconButton, Box, Stack, Tooltip, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import Logo from '../../../../assets/images/Budoar-Atelier-Logo.png';
import { useSetRecoilState } from 'recoil';
import { authAtom } from '@src/domain/atoms/authAtom';

const Sidebar = ({
  setIsOpenSidebar
}: {
  setIsOpenSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const sidebarItems = [
    { singular: 'Category', plural: 'categories', itemID: 1, icon: <CategoryIcon /> },
    { singular: 'Collection', plural: 'collections', itemID: 2, icon: <AppsIcon /> },
    { singular: 'Contact', plural: 'contacts', itemID: 3, icon: <PhoneIcon /> },
    { singular: 'Country', plural: 'countries', itemID: 4, icon: <LanguageIcon /> },
    { singular: 'Option', plural: 'options', itemID: 5, icon: <SettingsIcon /> },
    { singular: 'Order', plural: 'orders', itemID: 6, icon: <ReceiptIcon /> },
    { singular: 'Press', plural: 'presses', itemID: 7, icon: <CameraAltIcon /> },
    { singular: 'Product', plural: 'products', itemID: 8, icon: <InventoryIcon /> },
    { singular: 'Transaction', plural: 'transactions', itemID: 9, icon: <ReceiptIcon /> },
    { singular: 'User', plural: 'users', itemID: 10, icon: <PeopleIcon /> },
    { singular: 'Request', plural: 'requests', itemID: 11, icon: <PeopleIcon /> },
    { singular: 'Newsletter', plural: 'newsletters', itemID: 12, icon: <NewspaperIcon /> }
  ];

  const setAuthed = useSetRecoilState(authAtom);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const closeSidebar = () => {
    setIsOpenSidebar(false);
  };

  const lngs: any = {
    en: { nativeName: 'English' },
    sr: { nativeName: 'Srpski' }
  };

  return (
    <Stack
      sx={{
        width: '10vw',
        minWidth: '142px',
        backgroundColor: 'white',
        color: '#dee2e6',
        height: '100vh',
        paddingX: '15px',
        paddingTop: '10px',
        position: 'fixed',
        marginRight: '25px'
      }}
    >
      <Tooltip title={t('hideSidebar')}>
        <IconButton onClick={closeSidebar} disableRipple={true}>
          <MenuOpenIcon sx={{ color: '#212222' }} />
        </IconButton>
      </Tooltip>
      <Stack>
        <Button
          disableRipple={true}
          sx={{ color: 'white', width: '100%', fontSize: '2rem' }}
          onClick={() => {
            navigate('/');
            closeSidebar();
          }}
        >
          <img src={Logo} alt="Budoar logo" height="auto" width={160} />
        </Button>
        <Stack direction="row" className="inline-stack">
          <Tooltip title={t('userProfile')}>
            <Button
              disableRipple={true}
              onClick={() => {
                navigate('/user-info');
                closeSidebar();
              }}
            >
              <PersonIcon className="icon" />
            </Button>
          </Tooltip>
          <Tooltip title={t('logout')}>
            <Button
              disableRipple={true}
              onClick={() => {
                closeSidebar();
                setAuthed(false);
                setIsOpenSidebar(false);
                navigate('/');
                axiosInstance.post('/logout');
                localStorage.removeItem('token');
                localStorage.removeItem('adminID');
              }}
            >
              <LogoutIcon className="icon" />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack sx={{ height: '250vh', overflowY: 'auto' }}>
        <Stack>
          {sidebarItems.map(item => {
            return (
              <NavLink
                className={({ isActive }) => (isActive ? 'sidebar-link-active' : 'sidebar-link')}
                key={item.itemID}
                to={item.singular.trim().toLowerCase()}
                onClick={closeSidebar}
              >
                {item.icon}{' '}
                <Typography sx={{ marginLeft: '5px' }}>{t(`${item.plural}`)}</Typography>
              </NavLink>
            );
          })}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '2rem',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            marginTop: '2rem',
            mb: '4rem'
          }}
        >
          <Typography
            onClick={() => i18n.changeLanguage('en')}
            sx={[
              {
                color: '#808080'
              },
              {
                '&:hover': {
                  color: 'black'
                }
              }
            ]}
          >
            Eng
          </Typography>
          <Typography onClick={() => i18n.changeLanguage('sr')}  sx={[
              {
                color: '#808080'
              },
              {
                '&:hover': {
                  color: 'black'
                }
              }
            ]}
          >Sr</Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Sidebar;
