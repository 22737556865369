import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';

const useRedirect = () => {
  const token = localStorage.getItem('token');

  if (token) {
    return true
  }
  else return false;
};

export default useRedirect;
