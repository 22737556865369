import { Routes, Route, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// pages
import LoginPage from '@src/ui/pages/Login/Login';
import HomePage from './ui/pages/Home/Home';
import CustomRequestsPage from './ui/pages/Custom Requests/CustomRequests';
import UsersPage from './ui/pages/Users/Users';
import CountriesPage from './ui/pages/Countries/Countries';
import CategoriesPage from './ui/pages/Categories/Categories';
import ProductsPage from './ui/pages/Products/Products';
import OrdersPage from './ui/pages/Orders/Orders';
import CollectionsPage from './ui/pages/Collections/Collections';
import OptionsPage from './ui/pages/Options/Options';
import ContactsPage from './ui/pages/Contacts/Contacts';
import PressPage from './ui/pages/Press/Press';
import UserInfoPage from './ui/pages/User Info/UserInfo';
import TransactionsPage from './ui/pages/Transactions/Transactions';
import WelcomePage from './ui/pages/Welcome/Welcome';
import NewsletterPage from './ui/pages/Newsletter/Newsletter';
// components
import Sidebar from './ui/components/shared/sidebar/Sidebar';
import { Box, Paper } from '@mui/material';
import ShowSidebarButton from './ui/components/shared/button/ShowSidebarButton';
// styles
import './ui/styles/pages/global.scss';
import { useRecoilState } from 'recoil';
import { authAtom } from './domain/atoms/authAtom';
import { sidebarAtom } from './domain/atoms/sidebarAtom';
import RootLayout from './ui/layouts/RootLayout';

const router = createBrowserRouter([
  // { path: '*', Component: Root },
  {
    path: '/',
    element: <RootLayout />,
    children: [
      { path: 'home', element: <HomePage /> },
      { path: 'user', element: <UsersPage /> },
      { path: 'country', element: <CountriesPage /> },
      { path: 'category', element: <CategoriesPage /> },
      { path: 'product', element: <ProductsPage /> },
      { path: 'order', element: <OrdersPage /> },
      { path: 'collection', element: <CollectionsPage /> },
      { path: 'option', element: <OptionsPage /> },
      { path: 'contact', element: <ContactsPage /> },
      { path: 'press', element: <PressPage /> },
      { path: 'transaction', element: <TransactionsPage /> },
      { path: 'user-info', element: <UserInfoPage /> },
      { path: 'request', element: <CustomRequestsPage /> },
      { path: 'newsletter', element: <NewsletterPage /> }
    ]
  },
  { path: 'login', element: <LoginPage /> },
]);

export default function App() {
  // State controlling if the user is authenticated
  const [authed, setAuthed] = useRecoilState(authAtom);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuthed(true);
    }
  }, []);
  return <RouterProvider router={router} />;
}