import { authAtom } from '@src/domain/atoms/authAtom';
import axiosInstance from '@src/infrastructure/services/config';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const useFetch = (
  page: any,  
  pagination?: any,
  globalFilter?: string
) => {
  const navigate = useNavigate();
  const setAuthed = useSetRecoilState(authAtom);
  const { data, error, isError, isFetching, isLoading, isFetched, refetch } = useQuery({
    queryKey: ['data', page, globalFilter, pagination],
    keepPreviousData: true,
    queryFn: () => axiosInstance.get(`/${page}?search=${globalFilter}&page=${pagination.pageIndex + 1}&limit=${pagination.pageSize}`),
    onError: error => {
      console.log(error);
      setAuthed(false);
      //  setIsOpenSidebar(false);
      navigate('/');
      axiosInstance.post('/logout');
      localStorage.removeItem('token');
      localStorage.removeItem('adminID');
    },
    select: (data: any) => data.data.data
  });

  return { data, error, isError, isFetching, isLoading, isFetched, refetch };
};

export default useFetch;
