import { Collection } from '@src/domain/interfaces/collection';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PageLayout from '@src/ui/layouts/PageLayout';
import { Crud } from '@src/domain/interfaces/crud';
import Dropzone from '@src/ui/components/shared/dropzone/Dropzone';
import { string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { collectionErrorAtom } from '@src/domain/atoms/errorAtoms/CollectionErrorAtom';
import { Box, Typography, IconButton, TextareaAutosize } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';
import DeleteIcon from '@mui/icons-material/Delete';

const CollectionsPage: React.FC = (): JSX.Element => {
  const [collectionErrors, setCollectionErrors] = useRecoilState(collectionErrorAtom);
  const nameSchema = string().required();
  const subnameSchema = string().required();
  const { t } = useTranslation();
  const [initialSpecificData, setInitialSpecificData] = useState<{ Description: string }>({
    Description: ''
  });
  const [id, setID] = useState<number | undefined>();
  const [shownImages, setShownImages] = useState<any>();
  const columnDescriptionRef = useRef<any>();

  //every time user opens another collection editing modal, images pending for removal are reset
  useEffect(() => {
    localStorage.removeItem('removedImages');
  }, [id]);

  //when user clicks on a particular item, that item is fetched by id and data is shown in editing modal
  const { data: singleCollectionData } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/collection/${id}`);
    },
    queryKey: ['singleCollection', id],
    onSuccess: data => {
      setInitialSpecificData(data.data.data);
      setShownImages(data.data.data.Media);
    },
    enabled: id !== undefined
  });

  const handleRemoveImage = (file: any) => {
    const removedImages: string | null = localStorage.getItem('removedImages');
    if (removedImages !== null) {
      const parsedImages = JSON.parse(removedImages);
      // const newRemovedImages = JSON.parse(removedImages);
      if (!parsedImages.includes(file.ID)) {
        localStorage.setItem('removedImages', JSON.stringify([...parsedImages, file.ID]));
      }
    } else {
      localStorage.setItem('removedImages', JSON.stringify([file.ID]));
    }
  };

  const collectionsColumns = useMemo<MRT_ColumnDef<Collection>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: t('collectionName'),
        muiEditTextFieldProps: {
          required: true,
          error: !!collectionErrors?.Name,
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setCollectionErrors({ ...collectionErrors, Name: t('nameRequiredMsg') })
              );
          },
          helperText: collectionErrors?.Name,
          onFocus: () => {
            setCollectionErrors({ ...collectionErrors, Name: '' });
          }
        }
      },
      {
        accessorKey: 'Subname',
        header: t('collectionSubname'),
        muiEditTextFieldProps: {
          error: !!collectionErrors?.Subname,
          helperText: collectionErrors?.Subname,
          required: true,
          onFocus: () => {
            setCollectionErrors({ ...collectionErrors, Subname: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            subnameSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setCollectionErrors({ ...collectionErrors, Subname: t('subnameRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Description',
        header: t('description'),
        muiEditTextFieldProps: {
          error: !!collectionErrors?.Description,
          helperText: collectionErrors?.Description
        },
        Edit: ({ row }) => {
          setID(row.original.ID);
          return (
            <Box>
              <Typography>{t('description')}</Typography>
              <TextareaAutosize
                ref={columnDescriptionRef}
                value={columnDescriptionRef?.current?.value ?? ''}
                onChange={e => {
                  setInitialSpecificData((prevState: any) => {
                    return { ...prevState, Description: e.target.value };
                  });
                  row._valuesCache.Description = columnDescriptionRef?.current?.value;
                }}
                className="textarea"
              ></TextareaAutosize>
            </Box>
          );
        }
      },
      {
        accessorKey: 'Images',
        header: t('images'),
        Edit: () => {
          return (
            <Box>
              <Typography>{t('images')}</Typography>
              {shownImages?.map((file: any) => {
                return (
                  <Box key={file.ID} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <img src={file.URL} width={100} height={100} alt={file.AltText} />
                    </div>
                    <IconButton
                      disableRipple
                      onClick={() => {
                        handleRemoveImage(file);
                        setShownImages((prevImages: any) =>
                          prevImages.filter((image: any) => image !== file)
                        );
                      }}
                    >
                      <DeleteIcon sx={{ color: 'red' }}></DeleteIcon>
                    </IconButton>
                  </Box>
                );
              })}
              <Dropzone page="collection" />
            </Box>
          );
        }
      }
    ],
    [t, collectionErrors, initialSpecificData, singleCollectionData, shownImages]
  );

  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };

  return (
    <PageLayout
      title="Collections"
      columns={collectionsColumns}
      page="collection"
      crud={crud}
      specificData={initialSpecificData}
    />
  );
};

export default CollectionsPage;
