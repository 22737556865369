import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Title = ({ title }: { title: string }) => {
  const {t} = useTranslation()
  if (title === 'Press') {
    return <Typography sx={{ height: "4rem", fontWeight: 700, fontSize: "2rem"}}>Press</Typography>;
  } 
  return <Typography sx={{ height: "4rem", fontWeight: 700, fontSize: "2rem"}}>{t(`${title.toLowerCase()}`)} {t('page')}</Typography>;
};

export default Title;
