import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import useUpdateUser from '../hooks/authentication/useUpdateUser';
import { useTranslation } from 'react-i18next';

const UpdateUserModal = ({ data, id }: { data: any; id: string | null }) => {
  const {t} = useTranslation()
  const updateUserFn = useUpdateUser();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    Email: '',
    Name: '',
    Password: '',
    ID: id ? JSON.parse(id) : ''
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: formData
  });

  useEffect(() => {
    setFormData({
      Email: data?.Email,
      Name: data?.Name,
      Password: '',
      ID: id
    });
    reset(formData);
  }, [data, reset]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <Box>
      <Typography>{t('userInfo')}</Typography>
      <Typography>{t('changeAdminInfo')}</Typography>
      <Box
        component={'form'}
        onSubmit={handleSubmit(updateUserFn)}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Controller
          name="Email"
          control={control}
          render={({ field }) => (
            <FormControl margin="normal">
              <TextField label={t('email')} {...field} sx={{ width: '250px' }} />
            </FormControl>
          )}
        />
        <Controller
          name="Name"
          control={control}
          render={({ field }) => (
            <FormControl margin="normal">
              <TextField label={t("name")} {...field} sx={{ width: '250px' }} />
            </FormControl>
          )}
        />
        <Controller
          name="Password"
          control={control}
          render={({ field }) => (
            <FormControl margin="normal">
              <InputLabel htmlFor="Password">{t('password')}</InputLabel>
              <OutlinedInput
                sx={{ width: '250px' }}
                id="Password"
                {...field}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("password")}
              />
            </FormControl>
          )}
        />
        {
          <Box>
            <Button type="submit">{t('update')}</Button>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default UpdateUserModal;
