import { useMutation } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';

const useDelete = (page: string) => {
  const deleteFn = async (id: number) => {
        await axiosInstance({
          method: 'delete',
          url: `/${page}/${id}`
        });
  
  };

  const deleteMutation = useMutation({
    mutationFn: deleteFn
  });

  return {
    deleteMutation,
    deleteFn
  };
};

export default useDelete;
