import { atom } from 'recoil';

const userErrorAtom = atom({
  key: 'userError',
  default: {
    CountryID: '',
    Email: '',
    Firstname: '',
    Lastname: '',
    Address: '',
    City: '',
    Postcode: '',
    Phone: '',
    Password: ""
  }
});

export {userErrorAtom}
