import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo, useState } from 'react';
import PageLayout from '@src/ui/layouts/PageLayout';
import { Crud } from '@src/domain/interfaces/crud';
import { OrderRequestBody } from '@src/domain/interfaces/order';
import {
  Box,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';
import { number, string } from 'yup';
import { Product } from '@src/domain/interfaces/product';
import { useTranslation } from 'react-i18next';

const OrdersPage: React.FC = (): JSX.Element => {
  const [validationError, setValidationError] = useState({
    Metadata: '',
    Note: '',
    ShippingAddress: '',
    ShippingType: '',
    Subtotal: '',
    Total: '',
    UserID: ''
  });

  const [selectedProducts, setSelectedProducts] = useState<Product[] | []>([]);
  const [quantities, setQuantities] = useState<number[]>([]);
  const { t } = useTranslation();

  const handleSelectProduct = (event: any) => {
    const value = event.target.value;
    setSelectedProducts(value);
  };

  const handleQuantityChange = (index: number, val: number) => {
    const updatedQuantities = [...quantities];
    updatedQuantities[index] = val;
    setQuantities(updatedQuantities);
  };

  const metadataSchema = string().required();
  const noteSchema = string().required();
  const shippingAddressSchema = string().required();
  const shippingTypeSchema = string().required();
  const subtotalSchema = number().required();
  const totalSchema = number().required();
  const userIDSchema = number().required();

  const { data: productData } = useQuery({
    queryFn: () => axiosInstance('/product'),
    queryKey: ['products']
  });

  const ordersColumns = useMemo<MRT_ColumnDef<OrderRequestBody>[]>(
    () => [
      {
        accessorFn: row => `${row.Metadata.Firstname} ${row.Metadata.Lastname}`,
        header: t('customerName'),
        id: 'customer Name',
        enableEditing: false,
        muiEditTextFieldProps: {
          required: true,
          error: !!validationError.Metadata,
          helperText: validationError.Metadata,
          onFocus: () => {
            setValidationError({ ...validationError, Metadata: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            metadataSchema.validate(fieldValue).catch(() => {
              setValidationError({ ...validationError, Metadata: 'a' });
            });
          }
        }
      },
      {
        accessorKey: 'Subtotal',
        header: t('subtotal'),
        enableEditing: false,
        muiEditTextFieldProps: {
          error: !!validationError.Subtotal,
          helperText: validationError.Subtotal,
          onFocus: () => {
            setValidationError({ ...validationError, Subtotal: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            subtotalSchema.validate(fieldValue).catch(() => {
              setValidationError({ ...validationError, Subtotal: 'Subtotal is required field!' });
            });
          }
        }
      },
      {
        accessorKey: 'Total',
        header: t('total'),
        enableEditing: false,
        muiEditTextFieldProps: {
          error: !!validationError.Total,
          helperText: validationError.Total,
          onFocus: () => {
            setValidationError({ ...validationError, Total: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            totalSchema.validate(fieldValue).catch(() => {
              setValidationError({ ...validationError, Total: 'Total is required field!' });
            });
          }
        }
      },

      {
        accessorKey: 'Note',
        header: t('notes'),
        enableEditing: false,
        muiEditTextFieldProps: {
          error: !!validationError.Note,
          helperText: validationError.Note,
          onFocus: () => {
            setValidationError({ ...validationError, Note: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            noteSchema.validate(fieldValue).catch(() => {
              setValidationError({ ...validationError, Note: 'a' });
            });
          }
        }
      },
      {
        accessorKey: 'ShippingType',
        header: t('shippingType'),
        editVariant: 'select',
        enableEditing: false,
        editSelectOptions: ['PostExpress', 'Local'],
        muiEditTextFieldProps: {
          required: true,
          select: true
        }
      },
      {
        accessorKey: 'ShippingAddress',
        header: t('shippingAddress'),
        minSize: 230,
        enableEditing: false,
        muiEditTextFieldProps: {
          error: !!validationError.ShippingAddress,
          helperText: validationError.ShippingAddress,
          onFocus: () => {
            setValidationError({ ...validationError, ShippingAddress: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            shippingAddressSchema.validate(fieldValue).catch(() => {
              setValidationError({
                ...validationError,
                ShippingAddress: 'Shipping address is required!'
              });
            });
          }
        }
      },
      { accessorKey: 'Transactions', enableEditing: false, header: t('orderTransaction') },
      {
        accessorKey: 'Status',
        header: 'Status',
        muiEditTextFieldProps: { select: true },
        editVariant: 'select',
        editSelectOptions: [
          t('preauthorized'),
          t('authorized'),
          t('completed'),
          t('voided'),
          t('refunded'),
          t('shipped'),
          t('delivered')
        ]
      }
    ],
    [validationError, selectedProducts, productData, t]
  );
  const crud: Crud = { canCreate: false, canDelete: true, canEdit: true };

  return (
    <PageLayout
      title="Orders"
      columns={ordersColumns}
      page="order"
      crud={crud}
      selectedProducts={selectedProducts.map((product: Product, index: number) => {
        return { ProductID: product.ID, Quantity: quantities[index] };
      })}
    />
  );
};

export default OrdersPage;
