import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';


const ShowSidebarButton = (props: any) => {

  const {t} = useTranslation()
    
  return (
    <Tooltip title={t("showSidebar")}>
      <IconButton onClick={props.onClick} sx={{  width: '40px' }}>
        <MenuIcon />
      </IconButton>
    </Tooltip>
  );
};

export default ShowSidebarButton;
