import axiosInstance from '@src/infrastructure/services/config';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
const useUploadMedia = () => {
  const uploadMediaMutation = useMutation({
    mutationFn: ({
      file,
      id,
      page,
      indexBool,
      apiData
    }: {
      file: any;
      id: any;
      page: any;
      indexBool?: boolean;
      apiData?: any;
    }): any => {
      const formData = new FormData();
      formData.append('Media', file.data);
      return axiosInstance.post(
        `/${page}/upload-media/${id}`,
        { Media: file },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
    },
    onSuccess: (data: any, variables) => {
      if (variables.indexBool) {
        return axiosInstance.put(`/product/${variables.id}`, {
          ...variables.apiData,
          PreviewImage: data?.data.data
        });
      }
    }
  });
  return uploadMediaMutation;
};
export default useUploadMedia;
