import axiosInstance from '@src/infrastructure/services/config';
import { useMutation } from '@tanstack/react-query';
const useDeleteMedia = () => {
  const deleteMediaMutation = useMutation({
    mutationFn: ({ id, page }: { id: number; page: string }): any => {
      
      axiosInstance.delete(
        `/${page}/delete-media/${id}`,
      );
    }
  });
  return  deleteMediaMutation ;
};
export default useDeleteMedia;