import { useEffect, useMemo, useState } from 'react';
import { Press } from '@src/domain/interfaces/press';
import { MRT_ColumnDef } from 'material-react-table';
import PageLayout from '@src/ui/layouts/PageLayout';
import { Crud } from '@src/domain/interfaces/crud';
import Dropzone from '@src/ui/components/shared/dropzone/Dropzone';
import { string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { pressErrorAtom } from '@src/domain/atoms/errorAtoms/PressErrorAtom';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { imagesAtom } from '@src/domain/atoms/imagesAtom';

const PressPage = () => {
  const nameSchema = string().required();
  const [pressErrors, setPressErrors] = useRecoilState(pressErrorAtom);
  const { t } = useTranslation();
  const [id, setID] = useState<number | undefined>();
  const [shownImages, setShownImages] = useState<any>();

  //every time user opens another press editing modal, images pending for removal are reset
  useEffect(() => {
    localStorage.removeItem('removedImages');
  }, [id]);

  //when user clicks on a particular item, that item is fetched by id and data is shown in editing modal
  const { data: singleMediaData } = useQuery({
    queryFn: () => {
      return axiosInstance.get(`/press/${id}`);
    },
    queryKey: ['singlePress', id],
    onSuccess: data => {
      setShownImages(data.data.data.Media);
    },
    enabled: id !== undefined
  });

  const handleRemoveImage = (file: any) => {
    //if user wants to remove image it is stored in local storage and only on clicking save image is being deleted
    const removedImages: string | null = localStorage.getItem('removedImages');
    if (removedImages !== null) {
      const parsedImages = JSON.parse(removedImages);
      // const newRemovedImages = JSON.parse(removedImages);
      if (!parsedImages.includes(file.ID)) {
        localStorage.setItem('removedImages', JSON.stringify([...parsedImages, file.ID]));
      }
    } else {
      localStorage.setItem('removedImages', JSON.stringify([file.ID]));
    }
  };

  const pressColumns = useMemo<MRT_ColumnDef<Press>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: t('pressName'),
        muiEditTextFieldProps: {
          required: true,
          error: !!pressErrors.Name,
          helperText: pressErrors.Name,
          onFocus: () => {
            setPressErrors({ ...pressErrors, Name: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema.validate(fieldValue).catch(() => {
              setPressErrors({ ...pressErrors, Name: t('nameRequiredMsg') });
            });
          }
        }
      },
      {
        accessorKey: 'Type',
        header: t('coverageType'),
        editVariant: 'select',
        editSelectOptions: ['Review', 'Article', 'Interview'],
        muiEditTextFieldProps: {
          required: true,
          select: true,
          error: !!pressErrors.Type,
          helperText: pressErrors.Type,
          onFocus: () => {
            setPressErrors({ ...pressErrors, Type: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema.validate(fieldValue).catch(() => {
              setPressErrors({ ...pressErrors, Type: t('typeRequiredMsg') });
            });
          }
        }
      },
      {
        accessorKey: 'Date',
        header: t('pressDate'),
        muiEditTextFieldProps: {
          required: true,
          error: !!pressErrors.Date,
          helperText: pressErrors.Date,
          onFocus: () => {
            setPressErrors({ ...pressErrors, Date: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema.validate(fieldValue).catch(() => {
              setPressErrors({ ...pressErrors, Date: t('dateRequiredMsg') });
            });
          }
        },
        Edit: ({ row }) => {
          return (
            <Box marginTop={'2rem'}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t("pickADate")}
                  onChange={(value: any) => {
                    const datum = new Date(value.$d);
                    row._valuesCache.Date = datum;
                  }}
                />
              </LocalizationProvider>
            </Box>
          );
        }
      },
      {
        accessorKey: 'Description',
        header: t('description'),
        muiEditTextFieldProps: {}
      },
      {
        accessorKey: 'Images',
        header: t('productImage'),
        Edit: ({ row }) => {
          setID(row.original.ID);
          return (
            <Box>
              <Typography>{t('images')}</Typography>
              {shownImages?.map((file: any) => {
                return (
                  <Box key={file.ID} sx={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <img
                        src={file.URL}
                        width={100}
                        height={100}
                        alt={file.AltText}
                        // onLoad={() => {
                        //   //   URL.revokeObjectURL(file.preview);
                        // }}
                      />
                    </div>
                    <IconButton
                      disableRipple
                      onClick={() => {
                        setShownImages((prevImages: any) =>
                          prevImages.filter((image: any) => image !== file)
                        );
                        handleRemoveImage(file);
                      }}
                    >
                      <DeleteIcon sx={{ color: 'red' }}></DeleteIcon>
                    </IconButton>
                  </Box>
                );
              })}
              <Dropzone page='press'/>
            </Box>
          );
        }
      }
    ],
    [pressErrors, t, singleMediaData, shownImages]
  );
  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };

  return <PageLayout title="Press" columns={pressColumns} page="press" crud={crud} />;
};

export default PressPage;
