import React, { useMemo } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import PageLayout from '@src/ui/layouts/PageLayout';
import { Crud } from '@src/domain/interfaces/crud';
import { ContactMessage } from '@src/domain/interfaces/contact';
import { useTranslation } from 'react-i18next';

const ContactsPage: React.FC = (): JSX.Element => {
  const {t} = useTranslation()
  const contactsColumns = useMemo<MRT_ColumnDef<ContactMessage>[]>(
    () => [
      { accessorKey: 'Name', header: t('userName') },
      { accessorKey: 'Email', header: 'E-Mail' },
      { accessorKey: 'Message', header: t('message') }
    ],
    [t]
  );
  const crud: Crud = { canCreate: false, canDelete: true, canEdit: false };

  return <PageLayout title="Contacts" columns={contactsColumns} page="contact" crud={crud} />;
};

export default ContactsPage;
