import { Category } from '@src/domain/interfaces/category';
import { Option } from '@src/domain/interfaces/option';

const parseData = async ({
  page,
  values,
  selectedCategories,
  selectedProducts,
  specificData,
  checkedAvailable,
  checkedCurrently,
  subcategories,
  parentID,
  previewImage,
  available,
  productInDrafts
}: {
  page: string;
  values: any;
  selectedCategories?: any;
  selectedProducts?: any;
  specificData?: any;
  checkedAvailable?: number[];
  checkedCurrently?: number[];
  subcategories: {
    Name: string;
    Order: number;
  }[];
  parentID?: number;
  previewImage?: string;
  available?: boolean;
  productInDrafts?: boolean;
}) => {
  let apiData;
  switch (page) {
    case 'category':
      apiData = {
        ...values,
        CategoryOrder: +values.Order,
        Order: +values.Order,
        Children: subcategories.length ? subcategories : [],
        ParentID: parentID
      };
      return apiData;
    case 'country':
      apiData = { ...values, Order: +values.Order };
      return apiData;
    case 'order':
      apiData = { ...values, OrderID: +values.OrderID, Products: selectedProducts };
      return apiData;
    case 'product':
      const isItAvailable = (): boolean | undefined => {
        if (checkedAvailable?.length !== 0) {
          if (checkedCurrently?.length !== 0) {
            return true;
          }
          return false;
        }

        return available;
      };
      apiData = {
        ...values,
        Available: isItAvailable(),
        Categories: selectedCategories?.map((category: number) => ({ CategoryID: category })),
        Options: checkedAvailable?.map((num: number) => ({ OptionID: num })),
        Sku: values.Sku === '' ? 'N/A' : values.Sku,
        PreviewImage: previewImage ?? '',
        Draft: productInDrafts
      };
      return apiData;
    default:
      return values;
  }
};

export default parseData;
