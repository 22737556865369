import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          pleaseSignUp: 'Please sign up',
          backHome: 'Back to Home',
          email: 'Email',
          name: 'Name',
          password: 'Password',
          register: 'Register',
          home: 'Home Page',
          welcome: 'Welcome',
          userProfile: 'Admin Profile',
          logout: 'Log Out',
          page: 'page',
          categoryName: 'Category Name',
          orderingValue: 'Ordering Value',
          products: 'Products',
          collectionName: 'Collection Name',
          collectionSubname: 'Collection Subname',
          description: 'Description',
          images: 'Images',
          userName: 'User Name',
          message: 'Message',
          country: 'Country',
          internationalCode: 'International Code',
          customerName: 'Customer Name',
          order: 'Order',
          optionName: 'Option Name',
          optionType: 'Option Type',
          orderID: 'Order ID',
          user: 'User',
          subtotal: 'Subtotal',
          total: 'Total',
          orderInfo: 'Order Info',
          notes: 'Notes',
          shippingType: 'Shipping Type',
          shippingAddress: 'Shipping Address',
          pressName: 'Press Name',
          coverageType: 'Coverage Type',
          pressDate: 'Press Date',
          productName: 'Product Name',
          productDescription: 'Product Description',
          composition: 'Composition',
          sku: 'Stock Keeping Unit',
          vat: 'VAT',
          discountPercentage: 'Discount Percentage',
          discountPrice: 'Discount Price',
          originalPrice: 'Original Price',
          available: 'Available',
          productCategories: 'Product Categories',
          options: 'Options',
          previewImage: 'Preview Image',
          productImage: 'Product Image',
          paymentMethod: 'Payment Method',
          paymentAmount: 'Payment Amount',
          paymentProvider: 'Payment Provider',
          reference: 'Reference',
          status: 'Status',
          countryID: 'Country ID',
          firstName: 'First Name',
          lastName: 'Last Name',
          address: 'Address',
          city: 'City',
          postcode: 'Postcode',
          phone: 'Phone',
          customRequest: 'Custom Request',
          category: 'Category',
          categories: 'Categories',
          collection: 'Collection',
          collections: 'Collections',
          contact: 'Contact',
          contacts: 'Contacts',
          countries: 'Countries',
          option: 'Option',
          orders: 'Orders',
          press: 'Press',
          presses: 'Presses',
          product: 'Product',
          transaction: 'Transaction',
          transactions: 'Transactions',
          users: 'Users',
          request: 'Request',
          requests: 'Requests',
          subname: 'Subname',
          note: 'Note',
          createNewcategory: 'Create New Category',
          createNewcollection: 'Create New Collection',
          createNewcountry: 'Create New Country',
          createNewoption: 'Create New Option',
          createNewpress: 'Create New Press',
          createNewproduct: 'Create New Product',
          createNewuser: 'Create New User',
          pleaseLogIn: 'Please log in',
          login: 'Log in',
          nameRequiredMsg: 'Name is required field!',
          subnameRequiredMsg: 'Subname is required field!',
          orderRequiredMsg: 'Order is required field and must be a positive number!',
          descriptionRequiredMsg: 'Description is required field!',
          intlcodeRequiredMsg: 'International Code is required field!',
          typeRequiredMsg: 'Type is required field!',
          dateRequiredMsg: 'Date is required field!',
          compositionRequiredMsg: 'Composition is required field!',
          skuRequiredMsg: 'Sku is required field!',
          vatRequiredMsg: 'Vat is required field!',
          discountRequiredMsg: 'Discount must be a number between 0 and a 100!',
          discountpriceRequiredMsg: 'Discount price must be a number!',
          originalpriceRequiredMsg: 'Original price must be a number!',
          countryIDRequiredMsg: 'Country is required field!',
          emailRequiredMsg: 'You must enter a valid Email!',
          firstnameRequiredMsg: 'First name is required field!',
          lastnameRequiredMsg: 'Last name is required field!',
          addressRequiredMsg: 'Address is required field!',
          cityRequiredMsg: 'City is required field!',
          postcodeRequiredMsg: 'Postcode is required field!',
          phoneRequiredMsg: 'Phone number is required field!',
          passwordRequiredMsg: 'Password must be at least 8 characters',
          successfully: 'Successfully',
          unsuccessfully: 'Unsuccessfully',
          created: 'created',
          deleted: 'deleted',
          updated: 'updated',
          somethingWrong: 'Something went wrong',
          checkProductOption: 'Check available options for this product',
          checkCurrentOption: 'Check currently available options',
          productSubname: 'Product Subname',
          dragorclick: 'Drag or click to add images',
          isProductFeatured: 'Is product featured on homepage?',
          featured: 'Featured on homepage',
          orderStatus: 'Order status',
          addNewSubcategory: 'Add new subcategory',
          subcategoryName: 'Subcategory Name',
          greenBorder: 'Green border represents selected preview image',
          preauthorized: 'preauthorized',
          authorized: 'authorized',
          completed: 'completed',
          voided: 'voided',
          refunded: 'refunded',
          shipped: 'shipped',
          delivered: 'delivered',
          noPreviewImage: 'If no preview image is selected, first image will be set as preview',
          newsletters: 'Newsletters',
          featuredQuestion: 'Is the product featured on home page?',
          yesFeatured: 'Featured',
          noFeatured: 'Not featured',
          currentlyAvailable: 'Currently available',
          currentlyUnavailable: 'Currently unavailable',
          customrequests: 'Custom requests',
          newsletter: 'Newsletter',
          type: 'Type',
          date: 'Date',
          orderTransaction: 'Order transaction',
          paymentType: 'Payment type',
          orderLines: 'Order lines',
          pickADate: 'Pick a Date',
          showSidebar: 'Show Sidebar',
          hideSidebar: 'Hide Sidebar',
          delete: 'Delete',
          edit: 'Edit',
          userInfo: 'User Info',
          changeAdminInfo: 'Change Admin Info',
          update: 'Update',
          productInDifferentOptions: 'Is the product available in different options?',
          productCurrentlyAvailable: 'Is the product currently available?',
          unavailable: 'Unavailable',
          productStatus: 'Product Status',
          draftProducts:"Save Product as Draft"
        }
      },
      sr: {
        translation: {
          subname: 'Podime',
          pleaseSignUp: 'Registrujte se',
          backHome: 'Nazad na početnu',
          email: 'Email',
          name: 'Ime',
          password: 'Lozinka',
          register: 'Registracija',
          home: 'Početna stranica',
          welcome: 'Dobrodošli',
          userProfile: 'Admin profil',
          logout: 'Odjava',
          page: 'stranica',
          categoryName: 'Ime kategorije',
          orderingValue: 'Vrednost sortiranja',
          products: 'Proizvodi',
          collectionName: 'Ime kolekcije',
          collectionSubname: 'Podnaziv kolekcije',
          description: 'Opis',
          images: 'Slike',
          userName: 'Korisničko ime',
          message: 'Poruka',
          country: 'Zemlja',
          internationalCode: 'Međunarodni kod',
          order: 'Vrednost sortiranja?',
          optionName: 'Naziv opcije',
          optionType: 'Tip opcije',
          orderID: 'ID narudžbine',
          user: 'Korisnik',
          subtotal: 'Međuzbir',
          total: 'Ukupno',
          orderInfo: 'Informacije o narudžbini',
          notes: 'Beleške',
          note: 'Beleška',
          shippingType: 'Tip slanja',
          shippingAddress: 'Adresa za slanje',
          pressName: 'Naziv medija',
          coverageType: 'Tip pokrivenosti',
          pressDate: 'Datum',
          productName: 'Naziv proizvoda',
          productDescription: 'Opis proizvoda',
          composition: 'Sastav',
          sku: 'Šifra proizvoda',
          vat: 'PDV',
          discountPercentage: 'Procenat Popusta',
          discountPrice: 'Cena sa popustom',
          originalPrice: 'Originalna cena',
          available: 'Dostupno',
          productCategories: 'Kategorije proizvoda',
          options: 'Opcije',
          previewImage: 'Pregled slike',
          productImage: 'Slika proizvoda',
          paymentMethod: 'Način plaćanja',
          paymentAmount: 'Iznos plaćanja',
          paymentProvider: 'Pravilnik plaćanja',
          reference: 'Reference',
          status: 'Status',
          countryID: 'ID zemlje',
          firstName: 'Ime',
          lastName: 'Prezime',
          address: 'Adresa',
          city: 'Grad',
          postcode: 'Poštanski broj',
          phone: 'Telefon',
          customRequest: 'Prilagođeni zahtev',
          createNew: 'Dodajte Novu',
          category: 'Kategorija',
          categories: 'Kategorije',
          collection: 'Kolekcija',
          collections: 'Kolekcije',
          contact: 'Kontakt',
          contacts: 'Kontakti',
          countries: 'Zemlje',
          option: 'Opcija',
          orders: 'Narudžbine',
          press: 'Medij',
          presses: 'Mediji',
          product: 'Proizvod',
          transaction: 'Transakcija',
          transactions: 'Transakcije',
          users: 'Korisnici',
          request: 'Zahtev',
          requests: 'Zahtevi',
          createNewcategory: 'Dodajte Novu Kategoriju',
          createNewcollection: 'Dodajte Novu Kolekciju',
          createNewcountry: 'Dodajte Novu Zemlju',
          createNewoption: 'Dodajte Novu Opciju',
          createNewpress: 'Dodajte Novi Medij',
          createNewproduct: 'Dodajte Novi Proizvod',
          createNewuser: 'Dodajte Novog Korisnika',
          pleaseLogIn: 'Prijavite se',
          login: 'Prijavite se',
          nameRequiredMsg: 'Ime je obavezno polje!',
          orderRequiredMsg: 'Vrednost je obavezno polje i mora biti pozitivan broj!',
          subnameRequiredMsg: 'Podime je obavezno polje!',
          descriptionRequiredMsg: 'Opis je obavezno polje!',
          intlcodeRequiredMsg: 'Medjunarodni kod je obavezno polje!',
          typeRequiredMsg: 'Tip je obavezno polje!',
          dateRequiredMsg: 'Datum je obavezno polje!',
          compositionRequiredMsg: 'Kompozicija je obavezno polje!',
          skuRequiredMsg: 'Šifra proizvoda je obavezno polje!',
          vatRequiredMsg: 'PDV je obavezno polje i mora biti broj!',
          discountRequiredMsg: 'Popust mora biti broj izmedju 0 i 100!',
          discountpriceRequiredMsg: 'Cena sa popustom mora biti broj!',
          originalpriceRequiredMsg: 'Originalna cena mora biti broj!',
          countryIDRequiredMsg: 'Zemlja je obavezno polje!',
          emailRequiredMsg: 'Morate uneti validan Email!',
          firstnameRequiredMsg: 'Ime je obavezno polje!',
          lastnameRequiredMsg: 'Prezime je obavezno polje!',
          addressRequiredMsg: 'Adresa je obavezno polje!',
          cityRequiredMsg: 'Grad je obavezno polje!',
          postcodeRequiredMsg: 'Poštanski broj je obavezno polje!',
          phoneRequiredMsg: 'Broj telefona je obavezno polje!',
          passwordRequiredMsg: 'Šifra mora imati minimum 8 karaktera',
          successfully: 'Uspešno',
          unsuccessfully: 'Neuspešno',
          created: 'kreirana',
          deleted: 'obrisana',
          updated: 'izmenjena',
          somethingWrong: 'Došlo je do greške',
          checkProductOption: 'Označite opcije u kojima se proizvod može naći',
          checkCurrentOption: 'Označite trenutno dostupne opcije',
          productSubname: 'Podime proizvoda',
          dragorclick: 'Kliknite ovde ili prevucite da biste dodali slike',
          isProductFeatured: 'Da li se proizvod prikazuje na početnoj stranici?',
          featured: 'Prikazan na početnoj stranici',
          customerName: 'Ime mušterije',
          orderStatus: 'Status narudžbine',
          addNewSubcategory: 'Dodajte novu podkategoriju',
          subcategoryName: 'Ime podkategorije',
          greenBorder: 'Zeleni okvir predstavlja odabranu prednju sliku proizvoda',
          preauthorized: 'preauthorized',
          authorized: 'autorizovana',
          completed: 'završena',
          voided: 'poništena',
          refunded: 'refundirana',
          shipped: 'poslata',
          delivered: 'dostavljena',
          noPreviewImage:
            'Ako nijedna slika nije izabrana kao preview, prva slika će biti postavljena',
          newsletters: 'Newsletters',
          featuredQuestion: 'Da li je proizvod prikazan na početnoj stranici?',
          yesFeatured: 'Prikazan je',
          noFeatured: 'Nije prikazan',
          currentlyAvailable: 'Trenutno dostupna',
          currentlyUnavailable: 'Trenutno nije dostupna',
          customrequests: 'Posebni zahtevi',
          newsletter: 'Newsletter',
          type: 'Tip',
          date: 'Datum',
          orderTransaction: 'Transakcija narudžbine',
          paymentType: 'Vrsta plaćanja',
          orderLines: 'Sastav narudžbine',
          pickADate: 'Izaberite datum',
          showSidebar: 'Prikaži Sidebar',
          hideSidebar: 'Sakrij Sidebar',
          delete: 'Obriši',
          edit: 'Izmeni',
          userInfo: 'Informacije o Korisniku',
          changeAdminInfo: 'Promeni informacije o adminu',
          update: 'Izmeni',
          productInDifferentOptions: 'Da li je proizvod dostupan u različitim opcijama?',
          productCurrentlyAvailable: 'Da li je proizvod trenutno dostupan?',
          unavailable: 'Nedostupno',
          productStatus: 'Status Proizvoda',
          draftProducts: "Sačuvajte Proizvod kao Nacrt"
        }
      }
    }
  });

export default i18n;
