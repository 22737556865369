import { Box, Button, FormControl, Stack, TextField } from '@mui/material';
import { categoryErrorAtom } from '@src/domain/atoms/errorAtoms/CategoryErrorAtom';
import { Category } from '@src/domain/interfaces/category';
import { Crud } from '@src/domain/interfaces/crud';
import PageLayout from '@src/ui/layouts/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { number, string } from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';

const CategoriesPage: React.FC = (): JSX.Element => {
  const [subcategories, setSubcategories] = useState<any>([]);
  const { t } = useTranslation();
  const [categoryErrors, setCategoryErrors] = useRecoilState(categoryErrorAtom);
  const nameSchema = string().required();
  const orderSchema = number().required();

  const handleAddChildren = () => {
    const newSubCategory = { Name: '', Order: 1 };
    setSubcategories([...subcategories, newSubCategory]);
  };
  const handleRemoveChildren = (id: any) => {
    const leftCategories = [...subcategories];
    const newSubCategories = leftCategories.filter(
      (subcategory: any, index: number) => index !== id
    );
    setSubcategories(newSubCategories);
  };
  const handleNameChange = (index: number, e: any) => {
    const children = [...subcategories];
    children[index].Name = e.target.value;
    setSubcategories(children);
  };

  const categoriesColumns = useMemo<MRT_ColumnDef<Category>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: t('categoryName'),
        minSize: 200,

        muiEditTextFieldProps: {
          required: true,
          onFocus: () => setCategoryErrors({ ...categoryErrors, Name: '' }),
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema
              .validate(fieldValue)
              .catch(() => setCategoryErrors({ ...categoryErrors, Name: t('nameRequiredMsg') }));
          },
          helperText: categoryErrors?.Name,
          error: !!categoryErrors?.Name
        }
      },
      {
        accessorKey: 'Order',
        header: t('orderingValue'),
        muiEditTextFieldProps: {
          required: true,
          onFocus: () => setCategoryErrors({ ...categoryErrors, Order: '' }),
          onBlur: event => {
            const fieldValue = event.target.value;
            orderSchema
              .validate(fieldValue)
              .catch(() => setCategoryErrors({ ...categoryErrors, Order: t('orderRequiredMsg') }));
          },
          helperText: categoryErrors?.Order,
          error: !!categoryErrors?.Order
        }
      },
      {
        accessorKey: 'Children',
        header: 'Children',
        Edit: ({ row }) => {
          if (row.original.ParentID === 0 || row.original.ParentID === undefined) {
            return (
              <Box>
                <Button onClick={handleAddChildren}>{t('addNewSubcategory')}</Button>
                <Stack>
                  {subcategories.map((subcategory: any, index: number) => {
                    return (
                      <Stack
                        key={index}
                        sx={{ p: 3, gap: 4, display: 'flex', flexDirection: 'row' }}
                      >
                        <FormControl>
                          <TextField
                            id="subcategory-name"
                            label={t('subcategoryName')}
                            variant="standard"
                            onBlur={event => {
                              const fieldValue = event.target.value;
                              nameSchema
                                .validate(fieldValue)
                                .catch(() => console.log('Name is required field!'));
                            }}
                            onChange={e => handleNameChange(index, e)}
                          />
                        </FormControl>
                        <Button onClick={() => handleRemoveChildren(index)}>
                          <DeleteIcon sx={{ color: 'red' }} />
                        </Button>
                      </Stack>
                    );
                  })}
                </Stack>
              </Box>
            );
          }
        }
      }
    ],
    [categoryErrors, t, subcategories]
  );

  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };
  return (
    <PageLayout
      title="Categories"
      columns={categoriesColumns}
      page="category"
      crud={crud}
      subcategories={subcategories}
    />
  );
};

export default CategoriesPage;
