import { Box, Paper } from '@mui/material';
import React from 'react';
import Sidebar from '../components/shared/sidebar/Sidebar';
import ShowSidebarButton from '../components/shared/button/ShowSidebarButton';
import { authAtom } from '@src/domain/atoms/authAtom';
import { useRecoilState } from 'recoil';
import { sidebarAtom } from '@src/domain/atoms/sidebarAtom';
import { Outlet, useLocation } from 'react-router-dom';
import WelcomePage from '../pages/Welcome/Welcome';
import HomePage from '../pages/Home/Home';

const RootLayout = () => {
    const windowHeight = window.innerHeight;
    const [authed, setAuthed] = useRecoilState(authAtom);
    const [isOpenSidebar, setIsOpenSidebar] = useRecoilState(sidebarAtom);
    const location = useLocation();

  return (
    <Box sx={{ display: 'flex', margin: 0, flexDirection: 'column', height: windowHeight }}>
      {authed ? (
        isOpenSidebar ? (
          <Sidebar setIsOpenSidebar={setIsOpenSidebar} />
        ) : (
          <ShowSidebarButton onClick={setIsOpenSidebar} />
        )
      ) : (
        ''
      )}
      <Paper
        sx={{
          boxShadow: 0,
          marginTop: isOpenSidebar ? '20px' : '',
          paddingLeft: isOpenSidebar ? { xs: '180px', md: 'calc(90vw-150px)', lg: '13vw' } : '15px'
        }}
      >
        <main>
          {location.pathname === '/' ? !authed ? <WelcomePage /> : <HomePage /> : ''}
          <Outlet />
        </main>
      </Paper>
    </Box>
  );
};

export default RootLayout;
