import { Box, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

const LoadingPage = () => {
  return (
    <Box>
      <Typography>Loading ...</Typography>
      <Skeleton animation={'wave'} variant='rectangular' height={750} width={1280}/>
    </Box>
  );
};

export default LoadingPage;
