import axios from 'axios';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
      Accept: "application/json",
  }
});


 axiosInstance.interceptors.request.use(
   async function (config) {

    const token = localStorage.getItem('token')
     if (token) {
         config.headers.Authorization = `Bearer ${token}`
     }
     return config;
 },
 (error) => {
     return Promise.reject(error);
 }
 );

export default axiosInstance;