import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const {t} = useTranslation()
  return (
    <Box>
      <Typography>{t('home')}</Typography>
      <Typography>{t('welcome')}!</Typography>
    </Box>
  );
};

export default HomePage;
