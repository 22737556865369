import axiosInstance from '@src/infrastructure/services/config';
import UpdateUserModal from '@src/ui/modals/UpdateUserModal';
import { useQuery } from '@tanstack/react-query';

const UserInfoPage = () => {
  const adminID = localStorage.getItem('adminID');
  const { data } = useQuery({
    queryKey: ['admin'],
    queryFn: () => axiosInstance.get(`${adminID}`)
  });

  return <UpdateUserModal data={data?.data.data} id={adminID}/>;
};

export default UserInfoPage;
