import { atom } from 'recoil';

const productErrorAtom = atom({
  key: 'productError',
  default: {
    Name: '',
    Subname: '',
    Description: '',
    Composition: '',
    Sku: '',
    Vat: '',
    Discount: '',
    DiscountPrice: '',
    OriginalPrice: ''
  }
});

export {productErrorAtom}