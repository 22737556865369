import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from '@mui/material';
import { userErrorAtom } from '@src/domain/atoms/errorAtoms/UserErrorAtom';
import { Country } from '@src/domain/interfaces/country';
import { Crud } from '@src/domain/interfaces/crud';
import axiosInstance from '@src/infrastructure/services/config';
import PageLayout from '@src/ui/layouts/PageLayout';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { object, string, number } from 'yup';

const UsersPage: React.FC = (): JSX.Element => {
  const [userErrors, setUserErrors] = useRecoilState(userErrorAtom);
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>();

  const addressSchema = string().required();
  const citySchema = string().required();
  const countryIDSchema = number().required();
  const emailSchema = string().email().required();
  const firstNameSchema = string().required();
  const lastNameSchema = string().required();
  const passwordSchema = string().required().min(8);
  const phoneSchema = number().required();
  const postcodeSchema = string().required();

  const { data: countryData } = useQuery({
    queryKey: ['countries'],
    queryFn: () => axiosInstance('/country')
  });

  const handleSelectCountry = (event: any) => {
    const value = event.target.value;
    setSelectedCountry(value);
  };

  const usersColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'CountryID',
        header: t('countryID'),
        Edit: () => {
          return (
            <Box>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="user-countries-label">{t('countries')}</InputLabel>
                <Select
                  labelId="user-countries-label"
                  id="user-countries"
                  value={selectedCountry}
                  onChange={handleSelectCountry}
                  input={<OutlinedInput label="Product-category" />}
                >
                  {countryData?.data?.data?.Data?.map((country: Country) => {
                    return <MenuItem key={country.ID} value={country.ID}>{country.Name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
          );
        },
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.CountryID,
          helperText: userErrors.CountryID,
          onFocus: () => {
            setUserErrors({ ...userErrors, CountryID: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            countryIDSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, CountryID: t('countryIDRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Email',
        header: 'E-Mail',
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Email,
          helperText: userErrors.Email,
          onFocus: () => {
            setUserErrors({ ...userErrors, Email: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            emailSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Email: t('emailRequiredMsg')})
              );
          }
        }
      },
      {
        accessorKey: 'Firstname',
        header: t('firstName'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Firstname,
          helperText: userErrors.Firstname,
          onFocus: () => {
            setUserErrors({ ...userErrors, Firstname: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            firstNameSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Firstname: t('firstnameRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Lastname',
        header: t('lastName'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Lastname,
          helperText: userErrors.Lastname,
          onFocus: () => {
            setUserErrors({ ...userErrors, Lastname: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            lastNameSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Lastname: t('lastnameRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Address',
        header: t('address'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Address,
          helperText: userErrors.Address,
          onFocus: () => {
            setUserErrors({ ...userErrors, Address: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            addressSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Address: t('addressRequiredMsg')})
              );
          }
        }
      },
      {
        accessorKey: 'City',
        header: t('city'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.City,
          helperText: userErrors.City,
          onFocus: () => {
            setUserErrors({ ...userErrors, City: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            citySchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, City: t('cityRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Postcode',
        header: t('postcode'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Postcode,
          helperText: userErrors.Postcode,
          onFocus: () => {
            setUserErrors({ ...userErrors, Postcode: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            postcodeSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Postcode: t('postcodeRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Phone',
        header: t('phone'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Phone,
          helperText: userErrors.Phone,
          onFocus: () => {
            setUserErrors({ ...userErrors, Phone: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            phoneSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Phone: t('phoneRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Password',
        header: t('password'),
        muiEditTextFieldProps: {
          required: true,
          error: !!userErrors.Password,
          helperText: userErrors.Password,
          onFocus: () => {
            setUserErrors({ ...userErrors, Password: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            passwordSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setUserErrors({ ...userErrors, Password: t('passwordRequiredMsg') })
              );
          }
        },
        Cell: () => {
          return <Typography>You can't see it</Typography>;
        }
      }
    ],
    [userErrors, t, countryData, selectedCountry]
  );
  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };

  return <PageLayout title="Users" columns={usersColumns} page="user" crud={crud} />;
};

export default UsersPage;
