// MUI
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_EditActionButtons,
  MRT_ColumnDef,
  MRT_TableInstance,
  MRT_Row
} from 'material-react-table';
import { MRT_Localization_SR_LATN_RS } from 'material-react-table/locales/sr-Latn-RS';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  Tooltip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import '../../../styles/components/button.scss';

//interfaces
import { Crud } from '@src/domain/interfaces/crud';
import { Option } from '@src/domain/interfaces/option';
import { Category } from '@src/domain/interfaces/category';
import DetailsLayout from '@src/ui/layouts/DetailsLayout';
import { Product } from '@src/domain/interfaces/product';
import { Collection } from '@src/domain/interfaces/collection';
import { ContactMessage } from '@src/domain/interfaces/contact';
import { Country } from '@src/domain/interfaces/country';
import { OrderRequestBody } from '@src/domain/interfaces/order';
import { Press } from '@src/domain/interfaces/press';
//hooks
import { useTranslation } from 'react-i18next';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import useCreate from '@src/ui/hooks/useCreate';
import useDelete from '@src/ui/hooks/useDelete';
import useUpdate from '@src/ui/hooks/useUpdate';
import useValidate from '@src/ui/hooks/useValidate';
import useUploadMedia from '@src/ui/hooks/media/useUploadMedia';
import useDeleteMedia from '@src/ui/hooks/media/useDeleteMedia';
import { UseQueryResult } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

import i18n from '../../../../../i18n';
import axiosInstance from '@src/infrastructure/services/config';
import parseData from '@src/helpers/parse';
import { imagesAtom } from '@src/domain/atoms/imagesAtom';

const Table = ({
  columns,
  data,
  page,
  crud,
  refetch,
  isLoading,
  selectedCategories,
  selectedProducts,
  setSuccess,
  setError,
  setAction,
  specificData,
  checkedAvailable,
  checkedCurrently,
  subcategories,
  previewImage,
  available,
  globalFilter,
  setGlobalFilter,
  dataLength,
  pagination,
  setPagination,
  setProductAvailable,
  setProductCheckedAvailable,
  setProductCheckedCurrently,
  setProductShownImages,
  setPreviewImageURL,
  setFeatured,
  setProductDiffOptions,
  setSelectedCategories,
  productInDrafts,
  setProductInDrafts
}: {
  columns: MRT_ColumnDef<
    Category | Collection | ContactMessage | Country | Option | OrderRequestBody | Press | Product
  >[];
  data:
    | Category[]
    | Collection[]
    | ContactMessage[]
    | Country[]
    | Option[]
    | OrderRequestBody[]
    | Press[]
    | Product[]
    | [];
  page: string;
  crud: Crud;
  refetch: () => Promise<UseQueryResult>;
  isLoading: boolean;
  selectedCategories?: Category[];
  selectedProducts?: any;
  // selectedUser?: User;
  setSuccess?: SetStateAction<any>;
  setError?: SetStateAction<any>;
  setAction?: SetStateAction<any>;
  specificData?: any;
  checkedAvailable?: number[];
  checkedCurrently?: number[];
  subcategories?: any;
  previewImage?: string;
  dataLength: number;
  available?: boolean;
  globalFilter?: string;
  setGlobalFilter?: any;
  pagination?: { pageIndex: number; pageSize: number };
  setPagination?: SetStateAction<any>;
  setProductAvailable?: SetStateAction<any>;
  setProductCheckedAvailable?: SetStateAction<any>;
  setProductCheckedCurrently?: SetStateAction<any>;
  setProductShownImages?: SetStateAction<any>;
  setPreviewImageURL?: SetStateAction<any>;
  setFeatured?: SetStateAction<any>;
  setProductDiffOptions?: SetStateAction<any>;
  setSelectedCategories?: SetStateAction<any>;
  productInDrafts?: boolean;
  setProductInDrafts?: SetStateAction<boolean>;
}) => {
  const [tableData, setTableData] = useState<
    | Category[]
    | Collection[]
    | ContactMessage[]
    | Country[]
    | Option[]
    | OrderRequestBody[]
    | Press[]
    | Product[]
    | []
  >([]);
  const { createMutation } = useCreate(page);
  const { deleteMutation } = useDelete(page);
  const { updateMutation } = useUpdate(page);
  const { validateData } = useValidate(page);
  const deleteMediaMutation = useDeleteMedia();
  const uploadMediaMutation = useUploadMedia();
  const [images, setImages] = useRecoilState(imagesAtom);
  const globalFilterRef = useRef<any>();

  useEffect(() => {
    setImages([]);
  }, [page]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleDelete = (row: any) => {
    if (page === 'product') {
      updateMutation.mutate(
        { data: { ...row.original, Categories: [], Options: [], Media: [] }, id: row.original.ID },
        {
          onSuccess: () => {
            deleteMutation.mutate(row.original.ID, {
              onSuccess: () => {
                setSuccess(true);
                setTimeout(() => refetch(), 1000);
                //displaying success alert message
                setAction('deleted');
                setError(false);
              },
              onError: () => {
                //displaying error alert message
                setSuccess(false);
                setError(true);
                setAction('deleted');
              }
            });
          }
        }
      );
    } else {
      deleteMutation.mutate(row.original.ID, {
        onSuccess: () => {
          setSuccess(true);
          setTimeout(() => refetch(), 1000);
          //displaying success alert message
          setAction('deleted');
          setError(false);
        },
        onError: () => {
          //displaying error alert message
          setSuccess(false);
          setError(true);
          setAction('deleted');
        }
      });
    }
  };

  const { t } = useTranslation();

  const handleCreate = async ({
    row,
    table,
    values
  }: {
    row: MRT_Row<
      Category | Collection | ContactMessage | Country | Option | OrderRequestBody | Press | Product
    >;
    table: MRT_TableInstance<
      Category | Collection | ContactMessage | Country | Option | OrderRequestBody | Press | Product
    >;
    values: any;
  }) => {
    // on creating new row, there is data transformation to right type before sending it to the API

    const apiData = await parseData({
      page,
      values,
      selectedCategories,
      selectedProducts,
      specificData,
      checkedAvailable,
      checkedCurrently,
      subcategories,
      available,
      productInDrafts
    });

    const isError = await validateData(apiData);
    if (!isError) {
      createMutation.mutate(apiData, {
        onSuccess: (data: any, variables) => {
          table.setCreatingRow(null);
          setTimeout(() => refetch(), 1000);
          //displaying success alert message
          setSuccess(true);
          setAction('created');
          switch (page) {
            case 'product':
              checkedCurrently?.forEach((current: number) => {
                axiosInstance.put(`/product/toggle-option/${data.data.data.ID}/${current}`);
              });
              //this represents index value of a selected preview image
              const imageIndex = localStorage.getItem('imageIndex');
              let numImageIndex: number;
              if (imageIndex !== null) {
                numImageIndex = parseInt(imageIndex);
              }
              images.forEach((image: any, index: number) => {
                uploadMediaMutation.mutate({
                  file: image,
                  id: data.data.data.ID,
                  page,
                  //if this is true on successful upload product will be updated with url of new preview image
                  indexBool: index === numImageIndex,
                  apiData
                });
              });
              setImages([]);
              break;
            case 'collection':
              images.forEach((image: any, index: number) => {
                uploadMediaMutation.mutate({ file: image, id: data.data.data.ID, page });
              });
              setImages([]);
              break;
            case 'press':
              images.forEach((image: any, index: number) => {
                uploadMediaMutation.mutate({ file: image, id: data.data.data.ID, page });
              });
              setImages([]);
              break;
          }
          setProductAvailable && setProductAvailable(true);
          setProductCheckedAvailable && setProductCheckedAvailable([]);
          setProductCheckedCurrently && setProductCheckedCurrently([]);
          setProductShownImages && setProductShownImages([]);
          setPreviewImageURL && setPreviewImageURL('');
          setFeatured && setFeatured(false);
          setProductDiffOptions && setProductDiffOptions(true);
          setSelectedCategories && setSelectedCategories([]);
        },
        onError: () => {
          //displaying error alert message
          setError(true);
          setSuccess(false);
          setAction('created');
          table.setCreatingRow(null);
        }
      });
    }
  };

  const handleUpdate = async ({ row, values, table }: { row: any; values: any; table: any }) => {
    // on updating row, there is data transformation to right type before sending it to the API

    const apiData = await parseData({
      page,
      values,
      selectedCategories,
      selectedProducts,
      specificData,
      checkedAvailable,
      checkedCurrently,
      subcategories,
      parentID: row.original.ID,
      previewImage,
      productInDrafts
    });
    const isError = await validateData(apiData);
    if (!isError) {
      if (page === 'category') {
        await updateMutation.mutateAsync(
          { data: { CategoryOrder: 1, Name: apiData.Name }, id: row.original.ID },
          {
            //when updating category and adding new child categories on successful update
            //new category will be created with parentID of original category
            onSuccess: data => {
              apiData.Children.forEach((child: any) => {
                createMutation.mutate(
                  {
                    Name: child.Name,
                    ParentID: row.original.ID,
                    CategoryOrder: 1
                  },
                  {
                    onSuccess: () => {
                      //displaying success alert message
                      setSuccess(true);
                      setError(false);
                      setAction('updated');
                      table.setEditingRow(null);
                      setTimeout(() => refetch(), 1000);
                    },
                    onError: () => {
                      //displaying error alert message
                      setSuccess(false);
                      setError(true);
                      table.setEditingRow(null);
                      setAction('updated');
                    }
                  }
                );
              });
            },
            onError: () => {
              //displaying error alert message
              setSuccess(false);
              setError(true);
              table.setEditingRow(null);
              setAction('updated');
            }
          }
        );
      }
      if (page === 'order' && ['shipped', 'delivered'].includes(apiData.Status)) {
        if (apiData.Status === 'shipped') {
          axiosInstance.patch(`/order-shipped/${row.original.ID}`);
          table.setEditingRow(null);
          return;
        }
        if (apiData.Status === 'delivered') {
          axiosInstance.patch(`/order-delivered/${row.original.ID}`);
          table.setEditingRow(null);
          return;
        }
      } else {
        updateMutation.mutate(
          {
            //if product doesn't have multiple options then it's availability is based on boolean, otherwise it's based on if there is any available option
            data: {
              ...apiData
            },
            id: row.original.ID
          },
          {
            onSuccess: () => {
              //displaying success alert message
              setSuccess(true);
              setError(false);
              setAction('updated');
              table.setEditingRow(null);
              setTimeout(() => refetch(), 1000);
              if (page === 'collection' || page === 'press') {
                images.forEach((image: any, index: number) => {
                  uploadMediaMutation.mutate(
                    { file: image, id: row.original.ID, page },
                    {
                      onSuccess: () => {
                        setImages([]);
                      }
                    }
                  );
                });
              }
              if (page === 'product') {
                //see explanation in handleCreate function
                const imageIndex = localStorage.getItem('imageIndex');
                let numImageIndex: number;
                if (imageIndex !== null) {
                  numImageIndex = parseInt(imageIndex);
                }
                images.forEach((image: any, index: number) => {
                  uploadMediaMutation.mutate({
                    file: image,
                    id: row.original.ID,
                    page,
                    indexBool: index === numImageIndex,
                    apiData
                  });
                });
                setImages([]);
                const newAvailability =
                  checkedAvailable?.length === 0
                    ? available
                    : checkedCurrently?.length === 0
                    ? false
                    : true;

                if (row.original.Available !== newAvailability) {
                  axiosInstance.put(`/product/toggle-availability/${row.original.ID}`);
                }
                checkedCurrently?.forEach((currentOption: number) => {
                  axiosInstance.put(`/product/toggle-option/${row.original.ID}/${currentOption}`);
                });

                setProductAvailable && setProductAvailable(true);
                setProductCheckedAvailable && setProductCheckedAvailable([]);
                setProductCheckedCurrently && setProductCheckedCurrently([]);
                setProductShownImages && setProductShownImages([]);
                setPreviewImageURL && setPreviewImageURL('');
                setFeatured && setFeatured(false);
                setProductDiffOptions && setProductDiffOptions(true);
                setSelectedCategories && setSelectedCategories([]);
              }
              const removedImages: string | null = localStorage.getItem('removedImages');
              if (removedImages !== null) {
                const parsedImages = JSON.parse(removedImages);
                parsedImages.forEach((image: any) => {
                  deleteMediaMutation.mutate(
                    { id: image, page },
                    {
                      onSuccess: () => {
                        localStorage.removeItem('removedImages');
                      }
                    }
                  );
                });
              }
            },
            onError: () => {
              //displaying error alert message
              setSuccess(false);
              setError(true);
              table.setEditingRow(null);
              setAction('updated');
            }
          }
        );
      }
    }
  };

  const table = useMaterialReactTable({
    columns: columns,
    data: tableData,
    createDisplayMode: 'modal',
    enableClickToCopy: true,
    enableExpandAll: false,
    enableColumnResizing: true,
    enableEditing: true,
    //pagination is done on back end
    manualPagination: true,
    rowCount: dataLength,
    enableGlobalFilter: false,
    state: { pagination },
    initialState: {
      columnVisibility: {
        //hiding specific columns
        Children: page !== 'category',
        Products: false,
        Images: false,
        Transactions: false,
        PreviewImage: page !== 'product',
        Options: page !== 'product',
        Categories: page !== 'product',
        Password: false
      }
    },
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '0.6rem', maxWidth: '150px', marginRight: '2rem' }}>
        {crud.canDelete && (
          <Tooltip title={t('delete')}>
            <IconButton
              color="error"
              onClick={() => {
                handleDelete(row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {crud.canEdit && (
          <Tooltip title={t('edit')}>
            <IconButton
              onClick={() => {
                table.setEditingRow(row);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),
    muiTableHeadCellProps: {
      sx: {
        fontSize: '18px'
      }
    },
    paginationDisplayMode: 'pages',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={[{ display: 'flex', gap: '20px', flexDirection: { xs: 'column', md: 'row' } }]}>
        {crud.canCreate && (
          <Button
            variant="contained"
            sx={[
              { backgroundColor: 'green', color: '#dee2e6' },
              { '&:hover': { backgroundColor: '#dee2e6', color: 'green' } }
            ]}
            onClick={() => {
              table.setCreatingRow(true);
            }}
          >
            <AddCircleIcon sx={{ paddingRight: '4px' }} />
            {t(`createNew${page}`)}
          </Button>
        )}
        {page === 'product' && (
          <FormControl sx={{ minWidth: '200px' }}>
            <Input
              placeholder="Search by name"
              endAdornment={
                <Button
                  onClick={() => {
                    setGlobalFilter(
                      globalFilterRef?.current.value ? globalFilterRef.current.value : ''
                    );
                  }}
                  endIcon={<SearchIcon />}
                ></Button>
              }
              ref={globalFilterRef}
              onChange={e => {
                globalFilterRef.current.value = e.target.value;
              }}
            />
          </FormControl>
        )}
      </Box>
    ),
    onCreatingRowSave: handleCreate,
    localization: i18n.language === 'sr' ? MRT_Localization_SR_LATN_RS : undefined,
    onEditingRowSave: handleUpdate,
    onPaginationChange: setPagination,
    renderDetailPanel: (row: any) => <DetailsLayout id={row.row.original.ID} page={page} />,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle>{t(`createNew${page}`)}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle>Edit {page}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    )
  });

  return <MaterialReactTable table={table} />;
};

export default Table;
