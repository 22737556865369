import { Crud } from '@src/domain/interfaces/crud';
import PageLayout from '@src/ui/layouts/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const NewsletterPage = () => {
  const { t, i18n } = useTranslation();

  const newsletterColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'Email',
        header: t('email')
      }
    ],
    [t]
  );
  const crud: Crud = { canCreate: false, canDelete: false, canEdit: false };

  return (
    <PageLayout title="Newsletter" columns={newsletterColumns} page="newsletter" crud={crud} />
  );
};

export default NewsletterPage;
