import { useDropzone } from 'react-dropzone';
import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Icon,
  IconButton,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { imagesAtom } from '@src/domain/atoms/imagesAtom';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const Dropzone = ({
  page,
  previewImageURL,
  setPreviewImageURL
}: {
  page?: string;
  previewImageURL?: string;
  setPreviewImageURL?: any;
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [addedFiles, setAddedFiles] = useState<any>([]);
  const formData = new FormData();
  const [imageAtom, setImageAtom] = useRecoilState<any>(imagesAtom);
  const { t } = useTranslation();
  // const [previewImageURL, setPreviewImageURL] = useState<string>('');

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      formData.append('Media', file.data);
      Object.assign(file, {
        preview: `${URL.createObjectURL(file)}`
      });
      setImageAtom((prevImages: any) => [...prevImages, file]);
    });
    setUploadedFiles((prevFiles: any[]) => [...prevFiles, ...acceptedFiles]);
    setAddedFiles((prevFiles: any[]) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop
  });
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => addedFiles.forEach((file: any) => URL.revokeObjectURL(file.preview));
  }, []);

  const removeImage = (removed: any) => {
    setImageAtom((prevImages: any) => prevImages.filter((image: any) => image !== removed));
  };

  return (
    <Box sx={{ cursor: 'pointer', borderWidth: '1px', borderStyle: 'solid', borderColor: 'lightgrey', padding: "0.5rem" }}>
      <div>
        <div {...getRootProps({})}>
          <input {...getInputProps()} />
          <Typography>{t('dragorclick')}</Typography>
        </div>
      </div>
      {imageAtom?.map((file: any, index: number) => {
        return (
          <Box
            key={file.name}
            onClick={() => {
              localStorage.setItem('imageIndex', JSON.stringify(index));
              setPreviewImageURL(file.preview);
            }}
            sx={{ display: 'flex', flexDirection: 'row' }}
          >
            <Box
              sx={[
                file.preview === previewImageURL && {
                  borderColor: 'green',
                  borderWidth: '2px',
                  borderStyle: 'solid'
                },
                {
                  position: 'relative'
                }
              ]}
            >
              <img
                src={file.preview}
                height={100}
                onLoad={() => {
                  //   URL.revokeObjectURL(file.preview);
                }}
              />
              {file.preview === previewImageURL && (
                <Icon color="success" sx={{ position: 'absolute', bottom: '5px', right: '5px' }}>
                  <CheckCircleOutlineOutlinedIcon color="success" />
                </Icon>
              )}
            </Box>
            <IconButton disableRipple onClick={() => removeImage(file)}>
              <DeleteIcon sx={{ color: 'red' }}></DeleteIcon>
            </IconButton>
          </Box>
        );
      })}
    </Box>
  );
};

export default Dropzone;
