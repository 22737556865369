import { transactionErrorAtom } from '@src/domain/atoms/errorAtoms/TransactionErrorAtom';
import { Crud } from '@src/domain/interfaces/crud';
import PageLayout from '@src/ui/layouts/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { number, string } from 'yup';

const TransactionsPage = () => {
  const {t } = useTranslation()
  const transactionColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'OrderID',
        header: t('orderID'),
        muiEditTextFieldProps: {
          required: true,          
        }
      },
      {
        accessorKey: 'PaymentMethod',
        header: t('paymentMethod'),
        muiEditTextFieldProps: {
          required: true,          
        }
      },
      {
        accessorKey: 'PaymentAmount',
        header: t('paymentAmount'),
        muiEditTextFieldProps: {
          required: true,
        }
      },
      {
        accessorKey: 'PaymentProvider',
        header: t('paymentProvider'),
        muiEditTextFieldProps: {
          required: true, 
        }
      },
      {
        accessorKey: 'Reference',
        header: t('reference'),
        muiEditTextFieldProps: {}
      },
      {
        accessorKey: 'Status',
        header: t('status'),
        editVariant: 'select',
        editSelectOptions: ['Pending', 'Paid', 'Refunded', 'Canceled'],
        muiEditTextFieldProps: {
          required: true,
          select: true,
        }
      }
    ],
    [t]
  );
  const crud: Crud = { canCreate: false, canDelete: false, canEdit: false };

  return (
    <PageLayout title="Transactions" columns={transactionColumns} page="transaction" crud={crud} />
  );
};

export default TransactionsPage;
