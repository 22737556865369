import { atom } from 'recoil';

const transactionErrorAtom = atom({
  key: 'transactionErrors',
  default: {
    orderID: '',
    PaymentMethod: '',
    PaymentAmount: '',
    PaymentProvider: '',
    Status: ''
  }
});

export {transactionErrorAtom}
