import { countryErrorAtom } from '@src/domain/atoms/errorAtoms/CountryErrorAtom';
import { Country } from '@src/domain/interfaces/country';
import { Crud } from '@src/domain/interfaces/crud';
import PageLayout from '@src/ui/layouts/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { number, string } from 'yup';

const CountriesPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const [countryErrors, setCountryErrors] = useRecoilState(countryErrorAtom);
  const nameSchema = string().required();
  const orderSchema = number().required().positive();
  const countriesColumns = useMemo<MRT_ColumnDef<Country>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: t('country'),
        muiEditTextFieldProps: {
          required: true,
          helperText: countryErrors?.Name,
          error: !!countryErrors.Name,
          onFocus: () => {
            setCountryErrors({ ...countryErrors, Name: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setCountryErrors({ ...countryErrors, Name: t('nameRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'InternationalCode',
        header: t('internationalCode'),
        muiEditTextFieldProps: {
          required: true,
          helperText: countryErrors?.InternationalCode,
          error: !!countryErrors.InternationalCode,
          onFocus: () => {
            setCountryErrors({ ...countryErrors, InternationalCode: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setCountryErrors({ ...countryErrors, InternationalCode: t('intlcodeRequiredMsg') })
              );
          }
        }
      },
      {
        accessorKey: 'Order',
        header: t('order'),
        muiEditTextFieldProps: {
          required: true,
          helperText: countryErrors?.Order,
          error: !!countryErrors.Order,
          onFocus: () => {
            setCountryErrors({ ...countryErrors, Order: '' });
          },onBlur: event => {
            const fieldValue = event.target.value;
            orderSchema
              .validate(fieldValue)
              .catch(ValidationError =>
                setCountryErrors({ ...countryErrors, Order: t('orderRequiredMsg') })
              );
          }

        }
      }
    ],
    [t, countryErrors]
  );

  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };

  return <PageLayout title="Countries" columns={countriesColumns} page="country" crud={crud} />;
};

export default CountriesPage;
