import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import axiosInstance from '@src/infrastructure/services/config';

const useCreate = (page: string) => {
  const createFn = async (data: any) => {
   return await axiosInstance({
      method: 'post',
      url: `/${page}`,
      data
    });
  };

  const createMutation = useMutation({
    mutationFn: createFn
  });

  return {
    createMutation,
    createFn
  };
};

export default useCreate;
