import { Admin } from '@src/domain/interfaces/admin';
import axiosInstance from '@src/infrastructure/services/config';
import { loginSchema } from '@src/ui/validations/AdminValidation';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useLogin = () => {
  const loginMutation = useMutation({
    mutationFn: (data: Admin) => axiosInstance.post('/login', data, { withCredentials: false })
  });

  return loginMutation;
};

export default useLogin