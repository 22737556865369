import { optionErrorAtom } from '@src/domain/atoms/errorAtoms/OptionErrorAtom';
import { Crud } from '@src/domain/interfaces/crud';
import { Option } from '@src/domain/interfaces/option';
import PageLayout from '@src/ui/layouts/PageLayout';
import { MRT_ColumnDef } from 'material-react-table';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { string } from 'yup';

const OptionsPage: React.FC = (): JSX.Element => {
  const nameSchema = string().required();
  const typeSchema = string().required();
  const [optionErrors, setOptionErrors] = useRecoilState(optionErrorAtom);
  const { t } = useTranslation();

  const optionsColumns = useMemo<MRT_ColumnDef<Option>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: t('optionName'),
        muiEditTextFieldProps: {
          error: !!optionErrors.Name,
          helperText: optionErrors.Name,
          onFocus: () => {
            setOptionErrors({ ...optionErrors, Name: '' });
          },
          onBlur: event => {
            const fieldValue = event.target.value;
            nameSchema.validate(fieldValue).catch(() => {
              setOptionErrors({ ...optionErrors, Name: t('nameRequiredMsg') });
            });
          }
        }
      },
      {
        accessorKey: 'Type',
        header: t('optionType'),
        editVariant: 'select',
        editSelectOptions: ['Color', 'Cut', 'Size'],
        muiEditTextFieldProps: {
          error: !!optionErrors.Type,
          helperText: optionErrors.Type,
          onFocus: () => {
            setOptionErrors({ ...optionErrors, Type: '' });
          },
          select: true,
          onBlur: event => {
            const fieldValue = event.target.value;
            typeSchema.validate(fieldValue).catch(() => {
              setOptionErrors({ ...optionErrors, Type:t('typeRequiredMsg') });
            });
          }
        }
      }
    ],
    [optionErrors, t]
  );

  const crud: Crud = { canCreate: true, canDelete: true, canEdit: true };

  return <PageLayout title="Options" columns={optionsColumns} page="option" crud={crud} />;
};

export default OptionsPage;
