import { Admin } from '@src/domain/interfaces/admin';
import axiosInstance from '@src/infrastructure/services/config';
import React from 'react';

const useUpdateUser = () => {
  const updateUserFn = async (data: Admin) => {
    
     await axiosInstance({
         method: 'put',
         url: `/${data.ID}`,
         data: {...data, ID: +data.ID!}
       });
   }
  return updateUserFn;
};

export default useUpdateUser;
