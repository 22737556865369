import { Typography, Box } from '@mui/material';

const ErrorPage = () => {
  return (
    <Box sx={{backgroundColor: '#e9aba7', color: '#d13333', padding: '5px'}}>
      <Typography>Something went wrong</Typography>
      <Typography>Please try again.</Typography>
    </Box>
  );
};

export default ErrorPage;
